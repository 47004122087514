//import { useWhyDidYouUpdate } from 'hooks';
import { FieldLabel, SelectDB } from './../index';
import { FieldAttributes, useField, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Form, OverlayTrigger } from 'react-bootstrap';
import { OptionType } from '../select-db';
import FieldTooltip from './field-tooltip';

type Props = {
  as?: React.ElementType<any>;
  hook: () => any;
  where?: string;
  name: string;
  label?: string;
  recordLabel?: string;
  recordLabel2?: string;
  recordLabel3?: string;
  sbLabel: string;
  placeholder?: string;
  disableInactives?: boolean;
  disabled?: boolean;
  required?: boolean;
  minMenuHeight?: number;
  maxMenuHeight?: number;
  onChange?: (optioms: OptionType) => void;
} & FieldAttributes<any>;

export const FieldSelectDB = ({
  as = 'div',
  hook,
  name,
  where = '',
  sbLabel = '',
  label = 'Label',
  recordLabel = 'description',
  recordLabel2 = 'description2',
  recordLabel3 = 'description3',
  placeholder = '',
  disableInactives = false,
  disabled = false,
  required = false,
  minMenuHeight,
  maxMenuHeight,
  onChange = null,
}: Props) => {
  //
  //-------------------- Body -----------------------------------------------
  //
  const [{ value }, { touched, error, initialValue }] = useField<number>(name);
  const { setFieldValue, setFieldTouched } = useFormikContext<any>();

  const latestOnChange = useRef(onChange);
  useEffect(() => {
    latestOnChange.current = onChange;
  }, [onChange]);

  const setFieldValueAndTouched = useCallback(
    async (_value, touched: boolean) => {
      //await Promise.all([setFieldValue(name, _value, true), setFieldTouched(name, touched)]);
      await setFieldValue(name, _value, true); //field,value,shouldValidate
      await setFieldTouched(name, touched); //field,touched,shouldValidate
    },
    [name, setFieldTouched, setFieldValue],
  );

  // useEffect(() => {
  //   console.log('[FieldSelectDB] where changed');
  //   if (value !== null && where !== '') setFieldValueAndTouched(null, false);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [setFieldValueAndTouched, where]);

  //-------------------- Handlers -------------------------------------------
  //
  const handleChange = useCallback(
    (_option: OptionType) => {
      setFieldValueAndTouched(_option.value, true);
      !!latestOnChange.current && latestOnChange.current(_option);
    },
    [setFieldValueAndTouched],
  );

  const handleBlur = useCallback(async () => {
    await setFieldTouched(name, touched); //field,touched,shouldValidate
  }, [setFieldTouched, name, touched]);

  const changed = value !== initialValue;

  // useWhyDidYouUpdate(`[FieldSelectDB:${name}]`, {
  //   as,
  //   hook,
  //   where,
  //   label,
  //   sbLabel,
  //   placeholder,
  //   disabled,
  //   required,
  //   minMenuHeight,
  //   maxMenuHeight,
  //   value,
  //   error,
  // });
  //
  //-------------------- Render -------------------------------------------
  //
  return useMemo(
    () => (
      <Form.Group as={as} controlId={name} className="mb-2">
        <FieldLabel text={label} changed={changed} required={required} />
        <OverlayTrigger
          delay={{ show: 400, hide: 400 }}
          overlay={FieldTooltip({ text: name, value: value })}
          placement="top"
        >
          <div>
            <SelectDB
              id={name}
              className={name}
              hook={hook}
              disabled={disabled}
              required={required}
              where={where}
              placeholder={placeholder}
              value={value}
              label={sbLabel}
              recordLabel={recordLabel}
              recordLabel2={recordLabel2}
              recordLabel3={recordLabel3}
              maxMenuHeight={maxMenuHeight}
              minMenuHeight={minMenuHeight}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched && !!error}
              disableInactives={disableInactives}
            />
            {touched && !!error && (
              <div className="text-danger select-feedback"><small>{error}</small></div>
            )}

          </div>
        </OverlayTrigger>
      </Form.Group>
    ),
    [as, name, label, changed, required, value, hook, disabled, where, placeholder, sbLabel, recordLabel, recordLabel2, recordLabel3, maxMenuHeight, minMenuHeight, handleChange, handleBlur, touched, error, disableInactives],
  );
};

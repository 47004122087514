import React from "react";
import { useHistory } from "react-router-dom";


const HomePage = () => {
  //-------------------- Body -------------------------------------------
  //
  const history = useHistory();
  history.push('/orders');
  //-------------------- Handlers -------------------------------------------
  //



  //
  //-------------------- Render ----------------------------------------------
  //

  return (
<>
</>
  );
};

export default HomePage;
HomePage.whyDidYouRender = true;

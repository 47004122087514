import { RsDeliveriesPage } from "./../pages/reports/rs-deliveries/rs-deliveries";
import * as Lo from "lodash";
import React from "react";
import { generatePath } from "react-router-dom";

import {
  HomePage,
  CollectionPointsPage,
  TyreCollectionsPage,
  CollectionOrdersPage,
  ChangePwPage,
  ForgotPwPage,
  NewUserPage,
  CollectorStationsPage,
  CollectorResponsePage,
  StationsDeliveriesPage,
  WeightDifferencesPage,
  ConfirmRoutesPage,
  PlannedRoutesPage,
} from "../pages/index";
import { ResponseDaysPage } from "pages/reports/response-days/response-days-page";

interface RouteType {
  name: string;
  path: string;
  component: React.ComponentType<any>;
  state?: object;
}

export const routes: RouteType[] = [
  { name: "HomePage", path: "/", component: HomePage },
  {
    name: "CollectionPoints",
    path: "/collectionpoints",
    component: CollectionPointsPage,
  },
  {
    name: "TyreCollections",
    path: "/collections",
    component: TyreCollectionsPage,
  },
  {
    name: "CollectionOrders",
    path: "/orders",
    component: CollectionOrdersPage,
  },
  { name: "ChangePw", path: "/changepw", component: ChangePwPage },
  { name: "ForgotPw", path: "/forgotpw", component: ForgotPwPage },
  { name: "NewUser", path: "/newuser", component: NewUserPage },
  {
    name: "CollectorStations",
    path: "/collectorstations",
    component: CollectorStationsPage,
  },
  {
    name: "CollectorResponse",
    path: "/collectorresponse",
    component: CollectorResponsePage,
  },
  {
    name: "StationsDeliveries",
    path: "/stationsdeliveries",
    component: StationsDeliveriesPage,
  },
  {
    name: "WeightDifferences",
    path: "/weightdifferences",
    component: WeightDifferencesPage,
  },
  { name: "RsDeliveries", path: "/rsdeliveries", component: RsDeliveriesPage },

  {
    name: "RoutesConfirmation",
    path: "/confirm-routes",
    component: ConfirmRoutesPage,
  },
  {
    name: "PlannedRoutes",
    path: "/planned-routes",
    component: PlannedRoutesPage,
  },
  {
    name: "ResponseDays",
    path: "/response-days",
    component: ResponseDaysPage,
  },
];

export function getRouteByName(name: string): string {
  const idx = Lo.findIndex(routes, ["name", name]);
  if (idx === -1) return "/";
  return routes[idx].path;
}

export class Paths {
  static collectionPoints = () => {
    return generatePath(getRouteByName("CollectionPoints"));
  };

  static tyreCollections = () => {
    return generatePath(getRouteByName("TyreCollections"));
  };

  static collectionOrders = () => {
    return generatePath(getRouteByName("CollectionOrders"));
  };

  static changePw = () => {
    return generatePath(getRouteByName("ChangePw"));
  };

  static forgotPw = () => {
    return generatePath(getRouteByName("ForgotPw"));
  };

  static newUser = () => {
    return generatePath(getRouteByName("NewUser"));
  };

  static collectorStations = () => {
    return generatePath(getRouteByName("CollectorStations"));
  };

  static collectorResponse = () => {
    return generatePath(getRouteByName("CollectorResponse"));
  };

  static stationsDeliveries = () => {
    return generatePath(getRouteByName("StationsDeliveries"));
  };

  static weightDifferences = () => {
    return generatePath(getRouteByName("WeightDifferences"));
  };
}
